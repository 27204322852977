import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  //@ Games
  {
    path: "/hosting/games/",
    name: "Galactiq | Games",
    component: () => import("../views/games/games.vue"),
  },
  //@ Minecraft
  {
    path: "/hosting/minecraft/",
    name: "Galactiq | Minecraft",
    component: () => import("../views/games/minecraft/minecraft.vue"),
  },
  //@ ---- Java ----
  {
    path: "/hosting/minecraft/java",
    name: "Minecraft Java",
    component: () => import("../views/games/minecraft/minecraft.java.vue"),
  },
  {
    path: "/hosting/minecraft/java/standard",
    name: "Minecraft Standard",
    component: () => import("../views/games/minecraft/java/hosting.minecraft.standard.vue"),
  },
  {
    path: "/hosting/minecraft/java/budget",
    name: "Minecraft Budget",
    component: () => import("../views/games/minecraft/java/hosting.minecraft.budget.vue"),
  },
  {
    path: "/hosting/minecraft/java/free",
    name: "Minecraft Free",
    component: () => import("../views/games/minecraft/java/hosting.minecraft.free.vue"),
  },
  //@ ---- Bedrock ----
  {
    path: "/hosting/minecraft/bedrock/",
    name: "Minecraft Bedrock",
    component: () => import("../views/games/minecraft/bedrock/hosting.minecraft.bedrock.vue"),
  },
  //@ Steam
  {
    path: "/hosting/mindustry",
    name: "Mindustry Hosting",
    component: () => import("../views/games/steam/hosting.mindustry.vue"),
  },
  {
    path: "/hosting/steam/terraria",
    name: "Terraria Hosting",
    component: () => import("../views/games/steam/hosting.terraria.vue"),
  },
  {
    path: "/hosting/steam/satisfactory",
    name: "Satisfactory Hosting",
    component: () => import("../views/games/steam/hosting.satisfactory.vue"),
  },
  {
    path: "/hosting/steam",
    name: "Steam Servers",
    component: () => import("../views/games/steam/steam_servers.vue"),
  },
  //@ Misc
  {
    path: "/hosting/discord",
    name: "Discord Bot Hosting",
    component: () => import("../views/more/hosting.discord.vue"),
  },
  {
    path: "/hosting/trackmania",
    name: "Trackmania Hosting",
    component: () => import("../views/more/hosting.trackmania.vue"),
  },
//{
  //path: "/hosting/database",
  //name: "Database Hosting",
  //component: () => import("../views/more/hosting.database.vue"),
//},
//{
  //path: "/hosting/storage",
  //name: "Cloud Storage",
  //component: () => import("../views/more/hosting.cloudstorage.vue"),
//},
//{
  //path: "/hosting/apps",
  //name: "Cloud Apps",
  //component: () => import("../views/more/hosting.cloudapps.vue"),
//},
//{
  //path: "/hosting/owncast",
  //name: "OwnCast",
  //component: () => import("../views/more/hosting.owncast.vue"),
//},
  {
    path: "/hosting/more",
    name: "More Products",
    component: () => import("../views/more/more.vue"),
  }, 
  //@ Auctions
  {
    path: "/auctions/minecraft",
    name: "Minecraft Auction",
    component: () => import("../views/auctions/minecraft.auctions.vue"),
  },
  {
    path: "/hosting/auctions",
    name: "Auctions",
    component: () => import("../views/auctions/auctions.vue"),
  },
  //@ Cloud
  //{
    //path: "/hosting/vps/",
    //name: "Cloud VPS",
    //component: () => import("../views/cloud/hosting.vps.vue"),
  //},
  //@ Galactiq
  {
    path: "/terms-of-service",
    name: "Terms of Service",
    component: () => import("../views/galactiq/terms-of-service.vue"),
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () => import("../views/galactiq/privacy-policy.vue"),
  },
  {
    path: "/migration",
    name: "Host Migration",
    component: () => import("../views/blog/deals/migration.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/galactiq/contact.vue"),
  },
  {
    path: "/team",
    name: "Team",
    component: () => import("../views/galactiq/team.vue"),
  },
  {
   path: "/network",
   name: "Network",
   component: () => import("../views/galactiq/network.vue"),
  },
  {
    path: "/npo",
    name: "NPO's",
    component: () => import("../views/galactiq/npo.vue"),
  },  
  {
    path: "/service-providers",
    name: "Service Providers",
    component: () => import("../views/galactiq/service-providers.vue"),
  },  
  {
    path: "/education",
    name: "Education",
    component: () => import("../views/galactiq/education.vue"),
  },  
  //@ Login
  {
    path: "/login",
    name: "Services Login",
    component: () => import("../views/login/login.vue"),
  }, 
  {
    path: "/redirects/billing",
    name: "Billing",
    component: () => import("../views/login/billing.vue")
  },
  {
    path: "/redirects/pterodactyl",
    name: "Pterodactyl",
    component: () => import("../views/login/pterodactyl.vue")
  },
  //@ Hardware
  {
    path: "/hardware",
    name: "Hardware",
    component: () => import("../views/hardware/hardware.vue"),
  },
  //@ ---- Game Hardware ----
  {
    path: "/hardware/game-hardware",
    name: "Game Hardware",
    component: () => import("../views/hardware/game-hardware.vue"),
  },
  {
    path: "/hardware/game-hardware/us",
    name: "US Hardware",
    component: () => import("../views/hardware/pterodactyl-hardware/us.vue"),
  }, 
  //{
    //path: "/hardware/game-hardware/de",
    //name: "DE Hardware",
    //component: () => import("../views/hardware/pterodactyl-hardware/de.vue"),
  //},
  //@ ---- Bot Hardware ----
  //{
    //path: "/hardware/bot-hardware",
    //name: "Bot Hardware",
    //component: () => import("../views/hardware/bot-hardware.vue"),
  //},
  //{
    //path: "/hardware/bot-hardware/fi",
    //name: "FI Hardware",
    //component: () => import("../views/hardware/bot-hardware/fi.vue"),
  //},
  //@ ---- VPS Hardware ----
  //{
    //path: "/hardware/vps-hardware",
    //name: "VPS Hardware",
    //vcomponent: () => import("../views/hardware/vps-hardware.vue"),
  //},
  //{
    //path: "/hardware/vps-hardware/de",
    //name: "VPS Hardware",
    //component: () => import("../views/hardware/vps-hardware/de.vue"),
  //},
  //@ Partners
  {
    path: "/partners",
    name: "Partners",
    component: () => import("../views/partners/partners.vue"),
  },
  {
    path: "/partners/creators",
    name: "Creators",
    component: () => import("../views/partners/creators.vue"),
  },
  {
    path: "/partners/servers",
    name: "Servers",
    component: () => import("../views/partners/servers.vue"),
  },
  //@ Blogs
  {
    path: "/blogs",
    name: "Blogs",
    component: () => import("../views/blog/blogs.vue"),
  },
  {
    path: "/blogs/minecraft",
    name: "Minecraft Blogs",
    component: () => import("../views/blog/sub-pages/minecraft-blogs.vue"),
  },
  {
    path: "/blog/mc-blogs/4-18-23-MC-FOLIA",
    name: "Folia Implementation",
    component: () => import("../views/blog/mc-blogs/4-18-23-MC-FOLIA.vue"),
  },  
  {
    path: "/blog/mc-blogs/8-10-22-MC-249136",
    name: "1.19 Shipreck bug",
    component: () => import("../views/blog/mc-blogs/8-10-22-MC-249136.vue"),
  },  
  {
    path: "/blogs/galactiq",
    name: "Company Articles",
    component: () => import("../views/blog/sub-pages/galactiq-blogs.vue"),
  },
  {
    path: "/blog/galactiq-blogs/14-8-22-diskos-and-crewcraft-merge",
    name: "Company Merge",
    component: () => import("../views/blog/galactiq-blogs/14-8-22-diskos-and-crewcraft-merge.vue"),
  },
  {
    path: "/blog/galactiq-blogs/9-10-22-galactiq-updates",
    name: "10/9/22 Updates",
    component: () => import("../views/blog/galactiq-blogs/9-10-22-galactiq-updates.vue"),
  },
  {
    path: "/blog/galactiq-blogs/9-10-22-galactiq-ad-competition",
    name: "10/9/22 Ads",
    component: () => import("../views/blog/galactiq-blogs/9-10-22-galactiq-ad-competition.vue"),
  },
  {
    path: "/blog/galactiq-blogs/11-27-22-galactiq-tournament",
    name: "11-27-22 Tournament",
    component: () => import("../views/blog/galactiq-blogs/11-27-22-galactiq-tournament.vue"),
  },
  {
    path: "/blog/galactiq-blogs/1-19-23-galactiq-free-servers",
    name: "1-19-23 Free Servers",
    component: () => import("../views/blog/galactiq-blogs/1-19-23-galactiq-free-servers.vue"),
  },
  {
    path: "/blog/galactiq-blogs/3-22-23-galactiq-npo",
    name: "3-22-23 Galactiq NPO",
    component: () => import("../views/blog/galactiq-blogs/3-22-23-galactiq-npo.vue"),
  },
  {
    path: "/blog/galactiq-blogs/4-13-23-galactiq-partnerships",
    name: "4-13-23 Galactiq Partnerships",
    component: () => import("../views/blog/galactiq-blogs/4-13-23-galactiq-partnerships.vue"),
  },
  {
    path: "/blog/galactiq-blogs/4-19-23-galactiq-nat-vps",
    name: "4-19-23 Nat VPS",
    component: () => import("../views/blog/galactiq-blogs/4-19-23-galactiq-nat-vps.vue"),
  },
  {
    path: "/blog/galactiq-blogs/our-story",
    name: "Our Story",
    component: () => import("../views/blog/galactiq-blogs/our-story.vue"),
  },
  //@ Referrals
  {
    path: "/cloak",
    name: "CloakyBrokey",
    component: () => import("../views/refs/cloak.vue"),
  },
  {
    path: "/beerfunger",
    name: "Beerfunger",
    component: () => import("../views/refs/beerfunger.vue")
  },
  {
    path: "/rstarkid73",
    name: "RSTAR",
    component: () => import("../views/refs/rstarkid73.vue")
  },
  //@ Redirects
  {
    path: "/discord",
    name: "Discord",
    component: () => import("../views/redirects/discord.vue")
  },
  {
    path: "/redirects/paste",
    name: "Paste",
    component: () => import("../views/redirects/paste.vue")
  },
  {
    path: "/redirects/partnerships",
    name: "Partnerships",
    component: () => import("../views/redirects/partners.vue")
  },
  //@ Errors
  {
    path: "*",
    name: "404 not found",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;